var $owlFiltroProdutos = $('.owl-filtro-produtos');
$owlFiltroProdutos.owlCarousel({
    loop:false,
    margin:0,
    nav:false,
    items: 5,
    responsive: {
    	0: {
    		items:1
    	},
    	600: {
    		items:2
    	},
    	970: {
    		items:4
    	},
    	1170: {
    		items:5
    	}
    }
});
$('.owl-filtro-produtos-left').click(function() {
    $owlFiltroProdutos.trigger('prev.owl.carousel');
});
$('.owl-filtro-produtos-right').click(function() {
    $owlFiltroProdutos.trigger('next.owl.carousel');
});

var $resistencias = $('#resistencias-eletricas'),
    $aquecedores  = $('#aquecedores-industriais');

$resistencias.on('click', mudarCarouselProdutos);
$aquecedores.on('click', mudarCarouselProdutos);

function mudarCarouselProdutos(event) {
    $resistencias.removeClass('active');
    $aquecedores.removeClass('active');
    $(this).addClass('active');
    $owlFiltroProdutos.slideUp('fast');
    $this = $(this).data('carousel');
    $owlFiltroProdutos.each(function() {
        if ($(this).hasClass($this)) {
            $(this).slideDown('slow');
        }
    });
}

$("#product-thumb-slider").owlCarousel({
	items: 1,
	nav: true,
	dots: false
});
