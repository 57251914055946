var $modal = $('.modal'),
		$modal_open = $('.modal-open'),
		$modal_close = $('.modal-close'),
		$modal_input = $('#search-input');

$modal_open.on('click', function(event) {
	event.preventDefault();
	$data = $(this).data('modal');
	$modal.each(function() {
		$this = $(this);
		if ($this.data('modal') == $data) {
			$this.addClass('active');
		}
	});
	$modal_input.focus();
});

$modal_close.on('click', function(event) {
	event.preventDefault();
	$modal.removeClass('active');
});