/**
 * Função para enviar formulários com ajax. Requer: jquery-form e jquery-validation
 */
var $forms = $('.forms');
$forms.each(function() {
  $(this).validate({
    submitHandler: function(form, event) {
      event.preventDefault();
      var $this = $(form),
      $waiting =  $this.find('.waiting'),
      $success =  $this.find('.success'),
      $error =  $this.find('.error'),
      $response = $this.find('#response');
      $response.css('display', 'block');
      $waiting.css('display', 'block');
      $this.ajaxSubmit({
        url: 'http://www.skiakece.com.br/wp-content/themes/skiakece/inc/mail/mail.php',
        type: 'POST',
        success: function(response) {
          console.log(response);
          $this[0].reset();
          $waiting.css('display', 'none');
          $success.css('display', 'block');
        },
        error: function(response) {
          console.log(response);
          $success.css('display', 'none');
          $waiting.css('display', 'none');
          $error.css('display', 'block');
        }
      });
      setTimeout(function(){
        $success.css('display', 'none');
        $error.css('display', 'none');
        $response.css('display', 'none');
      }, 3000);
     }
  });
});
$.extend($.validator.messages, {
    required: "Campo obrigatório.",
    email: "E-mail inválido.",
    tel: "Telefone inválido."
});